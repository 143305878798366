<template>

  <div>
    <section class="selfcare-title d-flex">
      
    </section>

    <section class="p-2 terms">
      <div class="container">
        <p>
          <img style="width: 30px;" src="/img/18-plus.png">
          <br />
          <strong style="color: var(--white);">
            qwerty.com does not condone underage gambling. You must be 18 years of age or older to register or
            play at qwerty.com.
          </strong>
        </p>
        </div>
        <div class="p-2" v-html="content"></div>
    </section>
  </div>

</template>

<script>
import axios from "@/services/cms";

export default {
  name: 'Terms',
  components: {},
  data(){return{
    content:"",
  }},
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "terms");
    this.reloadProfile();
    this.getTerms();

  },
  methods:{
    async getTerms(){
try {
  const response = await axios.get("/get/tnc");
if (response.status == 200){
  this.content = response.data.content;
}
} catch (error) {
  console.log("An error occurred on terms and conditions::", error);
}      
    }
  }

}
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  padding: 20px;
}

h1,
h2 {
  margin-top: 30px;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5em;
}

p {
  margin-bottom: 10px;
}

.definition-list {
  margin-top: 20px;
}

.definition-list dt {
  font-weight: bold;
}

.definition-list dd {
  margin-bottom: 10px;
}
</style>